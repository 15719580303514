import { AppContext, IAppUserInf } from '../../app_context_provider/AppContextProvider';
import { getDictionariesService } from '../../../_services/dictionaries/DictionariesServices';
import { getProfileServices } from '../../../_services/profile/ProfileServices';
import { getToken } from '../../../_heplers/LocalStorageHelpers';
import { Loading } from '../loading/Loading';
import { setHeader } from '../../../_heplers/AxiosHelper';
import { useContext, useEffect } from 'react';

export const SpinPage = (props: any) => {
    const jwt = getToken();
    const context = useContext(AppContext);

    useEffect(() => {
        checkJWT(
            context.setIsAuthenticated,
            context.setBlockUi,
            context.setAppUserInf,
            context.setLoadingDictionaries,
            context.setDictionaries,
            jwt
        );
    }, [jwt]);

    return (
        <>{context.block_ui ? <Loading /> : props.children}</>
    )
};

const checkJWT = async (
    setIsAuthenticated: (is_authenticated: boolean) => void,
    setBlockUi: (block_ui: boolean) => void,
    setAppUserInf: (app_user_inf: IAppUserInf) => void,
    setLoadingDictionaries: any,
    setDictionaries: any,
    jwt: any
) => {
    if (jwt) {
        setHeader();

        await getProfileServices()
            .then(profile_inf => {
                profile_inf.permissions = profile_inf.features;
                profile_inf.availableSites = profile_inf.availableSites?.filter((item: any) => !item.archived);
                setAppUserInf(profile_inf);
            });

        await getDictionaries(
            setLoadingDictionaries,
            setDictionaries
        );

        setIsAuthenticated(true);
        setBlockUi(false);
    }
}

const getDictionaries = async (
    setLoadingDictionaries: (loading_dictionaries: boolean) => void,
    setDictionaries: (dictionaries: any) => void
) => {
    setLoadingDictionaries(true);

    await getDictionariesService()
        .then((response: any) => {
            setDictionaries({
                countries: response.Country,
                currencies: response.Currency,
                token_types: response.TokenType,
                widget_error_codes: response.WidgetErrorCode,
                widget_pages: response.WidgetPage,
                formula_types: response.FormulaType,
                fee_trigger_types: response.FeeTriggerType,
                fee_types: response.FeeType,
                sites: response.Sites,
                kyc_statuses: response.KycStatus,
                notification_types: response.NotificationType,
                coins: response.Coins,
                user_latest_kyc_status: response.UserLatestKycStatus,
                available_roles: response.AvailableRoles,
                features: response.features,
                access_mode: response.AccessMode,
                widgetUrl: response.WidgetUrl,
                risk_configs: response.RiskConfig,
                dashboard_filter_countries: response.DashboardFilterCountries,
                dashboard_filter_crypto: response.DashboardFilterCrypto,
                dashboard_filter_currency: response.DashboardFilterCurrency,
                crypto_source: response.CryptoSource,
                wire_transfer_transaction_type: response.WireTransferTransactionType,
                dashboard_analytics_filter_countries: response.DashboardAnalyticsFilterCountries,
                dashboard_analytics_filter_crypto: response.DashboardAnalyticsFilterCrypto,
                dashboard_analytics_filter_currency: response.DashboardAnalyticsFilterCurrency,
                order_statuses: response.OrderStatuses
            });
        })
        .finally(() => setLoadingDictionaries(false));

    return true;
}
