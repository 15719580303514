import _ from 'lodash';
import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import {
  Button,
  Form,
  InputNumber,
  Select,
  Spin
  } from 'antd';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { createMobilumPartnerService, updateMobilumPartnerService } from '../../../../_services/mobilum_partners/MobilumPartnersService';
import { FormInstance } from 'antd/lib/form';
import { IMobilumPartnerDetailsTechnicalSetUpFormProps } from '../interfaces/IMobilumPartnerDetailsTechnicalSetUpFormProps';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../../_common/NavPath';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../../_common/ValidationCominications';

const { Option } = Select;

const boolean_options = [{ key: 1, value: true, label: 'True' }, { key: 2, value: false, label: 'False' }];

export class MobilumPartnerDetailsTechnicalSetUpForm extends React.Component<IMobilumPartnerDetailsTechnicalSetUpFormProps> {
  _form_ref = React.createRef<FormInstance>();
  _is_mounted = true;

  state = {
    loading: false,
  }

  componentWillUnmount = () => {
    this._is_mounted = false;
  }

  public render() {
    const { mobilum_partner_details } = this.props;
    const { loading } = this.state;
    return (
      <Form
        layout="vertical"
        name="control-ref"
        ref={this._form_ref}
        onFinish={this.onFinish}
        validateMessages={validateMessages()}
        initialValues={{
          ...mobilum_partner_details,
          fiatOnlyMode: mobilum_partner_details.fiatOnlyMode ? true : false,
          alwaysTrusted: mobilum_partner_details.alwaysTrusted
            ? true
            : false,
        }}
        className='flex' style={{ flexDirection: 'column', height: '100%' }}
      >
        <Spin
          className="spin_60"
          spinning={loading}
          indicator={loading_icon(40)}
        >
          <div className='column_description'>Technical Set-up</div>
          <div className="grid_col_2">
            <Form.Item label="Partner’s Site Name" name="name" rules={[{ required: true }]}>
              <InputForm placeholder="https://lp.mobilum.com/buy#" />
            </Form.Item>
            <Form.Item label="API Key" name="Uuid" rules={[{ required: true }]}>
              <InputForm
                disabled
                placeholder="enter site siteUuid"
              />
            </Form.Item>
            <Form.Item
              label="Fiat Only"
              name="fiatOnlyMode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Please select"
                loading={this.props.loading_dictionaries}
                options={boolean_options}
              />
            </Form.Item>
            <Form.Item
              label="Min. Order Value"
              name="minOrder"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                size="large"
                precision={0}
                style={{ width: '100%' }}
                formatter={(value: any) =>
                  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Always trusted"
              name="alwaysTrusted"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select"
                showSearch
                options={boolean_options}
              />
            </Form.Item>
            <Form.Item label="KYC Provider" name="kycProvider">
              <InputForm
                placeholder="ex. Sumsub"
              />
            </Form.Item>
            <Form.Item
              name="siteFirstPage"
              label="First Page"
            >
              <Select
                showSearch
                placeholder='https://lp.mobilum.com/buy#'
                loading={this.props.loading_dictionaries}
              >
                {this.props.dictionaries &&
                  this.props.dictionaries.widget_pages?.map((x: any) => (
                    <Option key={x.key} value={x.name}>
                      {x.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="successUrl"
              rules={[{ type: 'url' }]}
              label="Success URL"
            >
              <InputForm placeholder="https://lp.mobilum.com/buy#" />
            </Form.Item>
            <Form.Item
              name="failUrl"
              rules={[{ type: 'url' }]}
              label="Fail URL"
            >
              <InputForm placeholder="https://lp.mobilum.com/buy#" />
            </Form.Item>
            <Form.Item
              name="redirectURL"
              rules={[{ type: 'url' }]}
              label="Redirect URL"
            >
              <InputForm placeholder="https://lp.mobilum.com/buy#" />
            </Form.Item>
            <Form.Item
              name="callbackUrl"
              rules={[{ required: true }, { type: 'url' }]}
              label="Call Back URL"
            >
              <InputForm placeholder="enter call back url" />
            </Form.Item>
          </div>
        </Spin>
        <div className='flex grid_col_2'
          style={{ marginTop: 'auto' }}
        >
          <Button
            className="common_previous_button"
            onClick={() => this.props.setCurrentStep(5)}
            disabled={loading}
          >
            Previous
          </Button>
          <Form.Item shouldUpdate={true}>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="common_next_button"
                disabled={this.showSuimtuBtton() || loading}
              >
                Done
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    );
  }

  showSuimtuBtton = () => {
    if (this._form_ref.current) {
      const errors = this._form_ref.current
        .getFieldsError()
        .filter(({ errors }) => errors.length).length;
      // Check if error
      if (errors) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  };

  onFinish = async (values: any) => {
    this.setState({ loading: true });
    let { mobilum_partner_details, updateState } = this.props;
    mobilum_partner_details = { ...mobilum_partner_details, ...values };
    if (mobilum_partner_details.isNew) {
      await createMobilumPartnerService(mobilum_partner_details)
        .then(() => {
          openSuccessNotification('Created');
          mobilum_partner_details.isNew = false;
          updateState(mobilum_partner_details);
          this.props.navigate(createAbsoluteUrl(navPath.mobilum_partners), { replace: true });
        })
        .finally(() => this._is_mounted && this.setState({ loading: false }));
    }
    else {
      await updateMobilumPartnerService(mobilum_partner_details)
        .then(() => {
          openSuccessNotification('Updated');
          this.props.navigate(createAbsoluteUrl(navPath.mobilum_partners), { replace: true });
        })
        .finally(() => this._is_mounted && this.setState({ loading: false }));
    }
  };
}
