import moment from 'moment';
import { Component, createContext } from 'react';
import { getToken } from '../../_heplers/LocalStorageHelpers';
import { IDictionary } from '../interfaces/IDictionary';

export const AppContext = createContext<any>({});

export interface ISetIsAuthenticated {
  setIsAuthenticated: (is_authenticated: boolean) => void;
}

export interface ISetBlockUi {
  setBlockUi: (block_ui: boolean) => void;
}

export interface IAppContextProps extends IAppContextProviderState, ISetIsAuthenticated, ISetBlockUi {
  setAppUserInf: (app_user_inf?: IAppUserInf) => void;
  setTransactionFilters: (type: keyof IAppContextProviderState, transaction_filters?: ITransactionFilters) => void;
  setPartnerAdminFilters: (filters?: any) => void;
  setMobilumPartnerFilters: (filters?: any) => void;
  setRegisteredUsersFilters: (filters?: any) => void;
  validatePermission: (permission: string) => boolean;
}

export interface IChildren {
  children: any;
}

export interface IAppUserInf {
  id?: string;
  permissions: Array<string>;
  availableSites: Array<any>;
  selectedSites: Array<string>;
  availableRoles: Array<any>;
}

export interface ITransactionFilters {
  firstDate: any,
  lastDate: any,
  country: string,
  currency: string,
  sites: string[],
  search: string,
  wireType: number | undefined,
  status: any,
  siteUuid: any,
  isSiteFilterDirty?: boolean
}

export interface IDictionaries {
  countries?: Array<IDictionary>;
  currencies?: Array<IDictionary>;
  token_types?: Array<IDictionary>;
  widget_error_codes?: Array<IDictionary>;
  widget_pages?: Array<IDictionary>;
  formula_types?: Array<IDictionary>;
  fee_trigger_types?: Array<IDictionary>;
  fee_types?: Array<IDictionary>;
  sites?: Array<IDictionary>;
  kyc_statuses?: Array<IDictionary>;
  notification_types?: Array<IDictionary>;
  coins?: Array<IDictionary>;
  user_latest_kyc_status?: Array<IDictionary>;
  available_roles?: Array<IDictionary>;
  features?: Array<IDictionary>;
  access_mode?: Array<IDictionary>;
  risk_configs?: Array<IDictionary>;
  dashboard_filter_countries?: Array<IDictionary>;
  dashboard_filter_crypto?: Array<IDictionary>;
  dashboard_filter_currency?: Array<IDictionary>;
  crypto_source?: Array<IDictionary>;
  wire_transfer_transaction_type?: Array<IDictionary>;
  dashboard_analytics_filter_countries?: Array<IDictionary>;
  dashboard_analytics_filter_crypto?: Array<IDictionary>;
  dashboard_analytics_filter_currency?: Array<IDictionary>;
  order_statuses?: Array<IDictionary>;
}

export interface IAppContextProviderState {
  // App User Info
  app_user_inf: IAppUserInf;
  // Helpers
  is_authenticated: boolean;
  block_ui: boolean;
  transaction_filters?: ITransactionFilters;
  wired_filters?: ITransactionFilters;
  partner_admin_filters?: any;
  mobilum_partner_filters?: any;
  registered_users_filters?: any;
  // Dictionaries
  loading_dictionaries: boolean;
  dictionaries?: IDictionaries;
}

export const initialTxStateFilters = {
    firstDate: moment(new Date()).subtract(7, 'days'),
    lastDate: moment(new Date()),
    country: '',
    currency: '',
    sites: [],
    search: '',
    wireType: undefined,
    status: null,
    siteUuid: null,
    isSiteFilterDirty: false
};

export default class AppContextProvider extends Component<
  IChildren,
  IAppContextProviderState
> {
  state = {
    // App User Info
    app_user_inf: {
      id: undefined,
      permissions: [],
      availableSites: [],
      selectedSites: [],
      availableRoles: []
    },
    // Helpers
    is_authenticated: false,
    block_ui: getToken() === null ? false : true,
    transaction_filters: {
      ...initialTxStateFilters
    },
    tx_current_page: 1,
    tx_per_page: 10,
    wired_filters: {
      ...initialTxStateFilters
    },
    // Dictionaries
    loading_dictionaries: false,
    dictionaries: {
      countries: [],
      currencies: [],
      token_types: [],
      widget_error_codes: [],
      widget_pages: [],
      formula_types: [],
      fee_trigger_types: [],
      fee_types: [],
      sites: [],
      kyc_statuses: [],
      notification_types: [],
      coins: [],
      user_latest_kyc_status: [],
      features: [],
      access_mode: [],
      risk_configs: [],
      dashboard_filter_countries: [],
      dashboard_filter_crypto: [],
      dashboard_filter_currency: [],
      crypto_source: [],
      wire_transfer_transaction_type: [],
      dashboard_analytics_filter_countries: [],
      dashboard_analytics_filter_crypto: [],
      dashboard_analytics_filter_currency: [],
      order_statuses: []
    },
    partner_admin_filters: {
      search: '',
      currentPage: 1,
      perPage: 10
    },
    mobilum_partner_filters: {
      search: '',
      currentPage: 1,
      showArchived: false,
      perPage: 10
    },
    registered_users_filters: {
      search: '',
      currentPage: 1,
      site: null,
      perPage: 10
    }
    
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          // App User Info
          setAppUserInf: this.setAppUserInf,
          // Helpers
          setIsAuthenticated: this.setIsAuthenticated,
          setBlockUi: this.setBlockUi,
          setTransactionFilters: this.setTransactionFilters,
          setPartnerAdminFilters: this.setPartnerAdminFilters,
          setMobilumPartnerFilters: this.setMobilumPartnerFilters,
          setRegisteredUsersFilters: this.setRegisteredUsersFilters,
          // Dictionaries
          setLoadingDictionaries: this.setLoadingDictionaries,
          setDictionaries: this.setDictionaries,
          // Permissions
          validatePermission: this.validatePermission
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }

  //#region ---- App User Info ----

  setAppUserInf = (app_user_inf: IAppUserInf) => {
    this.setState({ app_user_inf });
    this.setState({ wired_filters: { ...this.state.wired_filters, siteUuid: this.state.wired_filters.isSiteFilterDirty ? this.state.wired_filters.siteUuid : app_user_inf?.availableSites[0]?.siteUuid}})
  };

  //#endregion ---- App User Info ----

  //#region ---- Helpers ----

  setIsAuthenticated = (is_authenticated: boolean) => {
    this.setState({ is_authenticated });
  };

  setBlockUi = (block_ui: boolean) => {
    this.setState({ block_ui });
  };

  setTransactionFilters = (type: any, transaction_filters?: ITransactionFilters) => {
    this.setState({[type]: transaction_filters} as any);
  };

  setPartnerAdminFilters = (partner_admin_filters: any) => {
    this.setState({ partner_admin_filters })
  }

  setMobilumPartnerFilters = (mobilum_partner_filters: any) => {
    this.setState({ mobilum_partner_filters })
  }

  setRegisteredUsersFilters = (registered_users_filters: any) => {
    this.setState({ registered_users_filters })
  }

  //#endregion ---- Helpers ----

  //#region ---- Dictionaries ----

  setLoadingDictionaries = (loading_dictionaries: boolean) => {
    this.setState({ loading_dictionaries });
  };

  setDictionaries = (dictionaries: IDictionaries) => {
    this.setState({ dictionaries });
  };

  //#endregion ---- Dictionaries ----

  //#region ---- Validate prtmissions ----

  validatePermission = (permission: string) => {
    const permissions: Array<string> = this.state.app_user_inf.permissions ? this.state.app_user_inf.permissions : [];
    if (permissions.includes('SuperAdmin')) {
      return true;
    }

    if (permissions.includes(permission)) {
      return true;
    }
    else {
      return false;
    }
  };

  //#endregion ---- Validate prtmissions ----
}
